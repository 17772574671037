//! client-only

import Cookies from 'js-cookie';
import once from 'lodash/once';
import {
  AnalyticsServiceFieldKey,
  AnalyticsServiceFieldName,
  AnalyticsServiceFieldType,
} from '$util/analytics/analyticsService/types';
import { logError } from '../logger';
import type { AnalyticsParams, AnalyticsPayload, InternalAnalytics } from './types';

const logger: { error: typeof logError } = {
  error: (error, extra, logOnce) => logError(error, { scope: 'InternalAnalyticsClient', ...extra }, logOnce),
};

const makePayload = (sessionId: string, data: AnalyticsParams, checkoutSessionId?: string): string => {
  const payload: AnalyticsPayload = {
    ...data,
    sessionId,
    checkoutSessionId,
    source: 'landing',
    clientTimestamp: new Date().toISOString(),
  };
  return JSON.stringify(payload);
};

const getAnalyticsUrl = (fieldKey: string): string => {
  const url = new URL(window.location.origin);
  url.pathname = '/api/analytics';
  if (fieldKey) {
    url.searchParams.append(fieldKey, '');
  }
  return url.toString().replace(/=$|=(?=&)/g, '');
};

/**
 * @todo migrate all analytics events from Page Router
 */
export const internalAnalyticsClient: InternalAnalytics = {
  sendAnalytics(data: Readonly<AnalyticsParams>): void {
    const sessionId = Cookies.get('fixterAnalyticsSessionId');
    if (!sessionId) {
      logger.error('Unable to send analytics without a session id', data);
      return;
    }

    const checkoutSessionId = Cookies.get('fixterSessionId');
    const payload = makePayload(sessionId, data, checkoutSessionId);
    const url = getAnalyticsUrl(data.fieldKey);

    try {
      if (!window.navigator.sendBeacon(url, payload)) {
        logger.error('Unable to send analytics', data);
      }
    } catch (err) {
      logger.error(new Error('Failed to send analytics', { cause: err }), data);
    }
  },
};

/** **** *
 * @note export event functions individually for tree-shaking.
 *  **** */

export const sendAnalyticsPageView = once((): void => {
  const payload = {
    fieldName: AnalyticsServiceFieldName.GOOGLE_ANALYTICS,
    fieldKey: AnalyticsServiceFieldKey.PAGE_VIEW,
    fieldValue: encodeURIComponent(
      window.location.hostname + window.location.pathname + window.location.search
    ),
    fieldType: AnalyticsServiceFieldType.STRING,
  };
  internalAnalyticsClient.sendAnalytics(payload);
});

export const sendAnalyticsGclid = once((): void => {
  const gclid = new URLSearchParams(window.location.search).get('gclid');

  if (gclid) {
    internalAnalyticsClient.sendAnalytics({
      fieldName: AnalyticsServiceFieldName.MARKETING,
      fieldKey: AnalyticsServiceFieldKey.GCLID,
      fieldValue: gclid,
      fieldType: AnalyticsServiceFieldType.STRING,
    });
  }
});

export const sendCalloutCardContactUsEvents = (fieldKey: AnalyticsServiceFieldKey): void => {
  internalAnalyticsClient.sendAnalytics({
    fieldName: AnalyticsServiceFieldName.CALLOUT_CARD_CONTACT_US,
    fieldKey,
    fieldValue: JSON.stringify({
      page: window.location.pathname,
    }),
    fieldType: AnalyticsServiceFieldType.JSON,
  });
};
