import type { Locale } from '@fixter/i18n';

/**
 * @note While we're migrating Page Router pages to App Router, the components will share context on both routers.
 */
import { AppContext, useAppContext as useDeprecatedAppContext } from '$util/AppContext';

export interface AppContextData {
  readonly location: URL;
  readonly locale: Locale;
}

export const useAppContext = useDeprecatedAppContext as () => AppContextData;

export { AppContext };
